/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html, body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
body {
  background: url(../images/background.svg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Form */
fieldset {
  border: medium none;
  margin: 0 0 10px;
  padding: 10px 20px;
}
.label-sup, .label-note {
  width: 100%;
  display: inline-block;
  font-size: 0.7em;
  color: #CCC;
  position: relative;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}
.label-sup {
  top: -60px;
}
.label-note {
  text-align: right;
  font-size: 1em;
  top: -15px;
  color: red;
}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  width: 100%;
  border: 1px solid white;
  border-bottom: 1px solid #ccc;
  background: #FFF;
  margin: 5px 0px 0px 0px;
  padding: 8px 0px;
  outline: none;
}
input[type="text"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover,
input[type="url"]:hover,
input[type="password"]:hover,
textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border-bottom: 1px solid #aaa;
}
input[type="text"][disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="url"][disabled],
input[type="password"][disabled],
textarea[disabled] {
 background: #CCC;
}
textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}
button, .btn {
  cursor: pointer;
  width: 100%;
  border: 1px solid #2E9BD4;
  background: #2E9BD4;
  color: #fff;
  margin: 0 0 5px;
  padding: 12px 10px;
  font-size: 1rem;

  -webkit-transition: background 0.1s ease-in-out;
  -moz-transition: background 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
}
button:disabled, .btn:disabled {
  cursor: default;
  opacity: 0.5;
}
button:hover, .btn:hover {
  background: #1891D0;
}
button:active, .btn:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}
button.btn-secondary {
  color: #2E9BD4;
  background: white;
  border: 1px solid #2E9BD4;
}

a.btn {
  text-decoration: none;
  text-align: center;
  display: inline;
}
::-webkit-input-placeholder {
  color: #888;
}
:-moz-placeholder {
  color: #888;
}
::-moz-placeholder {
  color: #888;
}
:-ms-input-placeholder {
  color: #888;
}

.checkbox {
  cursor: pointer;
  color: #888;
  font-size: 0.9rem;
  line-height: 16px;
  display: block;
  position: relative;
  padding: 10px 0px 10px 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox .checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #CCC;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox :hover input ~ .checkmark {
  background-color: white;
}
.checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox .checkmark svg {
  height: 14px;
  width: 14px;
  fill: white;
  position: absolute;
}

.checkbox-small {
  font-size: 0.75rem;
  line-height: 7px;
  padding-left: 20px;
}
.checkbox-small .checkmark {
  height: 14px;
  width: 14px;
  top: 6px;
}
.checkbox-small .checkmark svg {
  height: 12px;
  width: 12px;
}
.social {
  display: flex;
  flex-wrap: wrap;
}
.social button {
  flex: 1;
}
.social button:active {
  box-shadow: none;
}
.google {    
  height: 40px;
  color: transparent;
  line-height: 1rem;
  width: auto;
  background: url(../images/btn_google_signin_dark_normal_web@2x.png) no-repeat left top;
  background-size: contain;
  border: none;
  margin: 0px;
}
.google:hover {
  background: url(../images/btn_google_signin_dark_focus_web@2x.png) no-repeat left top;
  background-size: contain;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(51, 51, 51, 0.75);
}
.modal {
  position: relative;
  top: 10%;
  display: block;
}
.modal:focus, .modal:active {
  border: none;
  outline: none;
}
.modal-header {
  position: relative;
  border-radius: 0;
}
.modal-header h1 {
  color: #1891D0;
  margin-top: 0px;
}
.modal-content {
  border: none;
  border-radius: 0;
}
.modal .close {
  color: #ccc;
  width: auto;
  padding: 8px 10px;
  background: transparent;
  border: none;
  outline: none;
  text-shadow: none;
  position: absolute;
  top: -15px;
  right: -15px;
}
.modal .close:focus {
  outline: none;
}

/* Box and List Wrapper */
.box, .list-item, .modal-content {
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: white;
  border: 1px solid #CCC;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  position: relative;
}
.box h1 {
  color: #1891D0;
  margin: 10px 0px 20px 0px;
  padding: 0px;
}

.list {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.list-item {
  max-width: 1000px;
  margin: 5px 0px 20px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.list-item-col {
  color: #666666;
  flex: 1;
}
.list-item-col-2 {
  flex: 2 !important;
}
.list-item-col-3 {
  flex: 3 !important;
}
.list-item-col-half {
  flex: 0.5 !important;
}

.list-item-col h3 {
  color: #666666;
  font-weight: bold;
  font-size: 1.1rem;
}
.list-item-col p {
  font-size: 0.95rem;
  text-align: left;
}

.list-item-col-header {
  color: white;
  position: absolute;
  top: -30px;
  display: block;
}
.list-item ~ .list-item .list-item-col-header{ 
  display: none;
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.grid-col {
  flex: 1;
}

.download-progress {
  width: 100%;
  height: 44px;
  border: 1px solid #2E9BD4;
  background: #CCC;
  margin: 0px 0px 5px 0px;
  padding: 0px;
  position: relative;
}
.download-progress-bar {
  height: 42px;
  background: #2E9BD4;
  margin: 0px;
  padding: 0px;
}
.download-progress-percent {
  width: 100%;
  line-height: 42px;
  text-align: center !important;
  color: white;
  font-size: 1rem;
  z-index: 10;
  display: block;
  position: absolute;
  top: 0px;
}

.actions {
  height: 44px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
.actions-align-center {
  text-align: center;
}
.actions-align-left {
  text-align: left;
}
.actions li {
  position: relative;
  display: inline;
}
.actions button,
.actions a {
  width: auto;
  height: auto;
  background: none;
  border: none;
  margin: 5px 0px;
  padding: 5px;
}
.actions button svg,
.actions a svg {
  height: 24px;
  width: 24px;
}
.actions button:disabled,
.actions a:disabled {
  opacity: 0.15;
}
.action-progress {
  width: 38px;
  height: 36px;
  color: white;
  line-height: 25px;
  font-size: 0.8rem;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: -6px;
  left: -2px;
  padding: 5px;
}
.action-subtext {
  color: white;
  font-size: 0.65rem;
  text-shadow: 0px 0px 1px black;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: -5%;
  pointer-events: none
}
.action-subtext ~ button svg {
  opacity: 0.5;
}
.action-dropdown {
  color: #666666;
  background: white;
  border: 1px solid #666666;
  position: absolute;
  top: 24px;
  right: 0px;
  z-index: 9999;
  display: none;
  min-width: 200px;
} 
.action-dropdown button {
  color: #666;
  text-align: left;
  width: 100%;
  padding: 10px;
  margin: 0px;
}
.action-dropdown button:hover {
  background: #eeeeee !important;
}
.actions button:hover ~ .action-dropdown, .action-dropdown:hover {
  display: block;
}

.actions-dark svg {
  fill: #666;
}
.actions-dark .action-progress {
  color: #666;
}

.marker {
  position: absolute;
  top: -2px;
  left: 0px;
}
.marker svg {
  width: 18px;
  height: 18px;
}
.marker svg.complete {
  fill: green;
}
.marker svg.not-complete {
  fill: red;
}

/* Toasty */
.Toastify__close-button {
  width: 24px;
  height: 24px;
  margin: 0px;
  padding: 0px;
}
.Toastify__close-button:hover {
  background-color: transparent;
}
.Toastify__toast-body {
  text-align: left;
}

/* VideoJS */
.player.viewer .vjs-tech {
  pointer-events: none;
}

/* Navigation */
.nav {
  color: white;
  width: 100%;
  height: 50px;
  margin: 0px;
  padding: 0px 10px;
  background-color: #1891D0;
  position: relative;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
}
.nav a {
  color: white;
  text-decoration: none;
}
.nav .logo {
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 50px;
  margin: 0px;
  padding: 0px;
}
.nav ul {
  position: absolute;
  top: 0;
  right: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.nav li {
  display: inline-block;
}
.nav li a {
  font-size: 0.9rem;
  line-height: 50px;
  padding: 0px 10px;
  border-right: 1px solid white;
}
.nav li a.nav-last {
  border: none;
}
.nav li a:hover {
  text-decoration: underline;
}

/* Home */
.home {
  width: 100%;
}
.home .header {
  color: white;
  text-transform: uppercase;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.home .message, .home .image {
  flex: 1;
}
.home .message h1{
  font-size: 3.2rem;
  line-height: 60px;
  margin: 80px 0px 20px 0px;
  padding: 0;
}
.home .message h2{
  font-size: 1.9rem;
  font-weight: normal;
  line-height: 35px;
  margin: 0px;
  padding: 0px;
}
.home .message a {
  color: white;
  font-size: 1.8rem;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background: #1674A5;
  display: block;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}
.home .message a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.25);
  box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.25);
}
.home .image {
  margin-left: 50px;
}
.home .image img {
  width: 100%;
  height: auto;
}
.home .howto {
  width: 100%;
  background: white;
}
.home .howto .tab-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

/* Create Room */
.room-create form {
  display: flex;
  flex-wrap: wrap;
}
.room-create fieldset {
  flex: 1;
}
.room-create .create {
  border-right: 1px solid #CCC;
}
.room-create .create .movieFile {
  position: relative;
  margin-top: -16px;
}
.room-create .create .movieFile label {
  position: relative;
  top: 0;
}
.room-create .create .movieFile-select {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
}
.room-create .create .movieFile-select-item {
  flex: 25%;
  max-width: 25%;
  padding: 0 2%;
  margin-right: 1%;
  cursor: pointer;
  border: 1px solid transparent;
}
.room-create .create .movieFile-select-item:hover {
  border: 1px solid rgba(0,0,0,0.1);
}
.room-create .create .movieFile-select-item img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.room-create .create .sudoInput {
  color: #888;
  width: 100%;
  border: 1px solid white;
  border-bottom: none;
  background: #FFF;
  margin: 5px 0px 0px 0px;
  padding: 8px 0px;
  outline: none;
}
.room-create .create .dropzone {
  color: #666;
  text-align: center;
  height: 200px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0px 0px 10px 0px;
} 
.room-create .create .dropzone .icon-drop {
  width: 80px;
  height: 80px;
  margin: 5px;
  fill: #1891D0;
}
.room-create .create .dropzone button {
  cursor: pointer;
  width: 140px;
  border: 1px solid #2E9BD4;
  background: #2E9BD4;
  color: #fff;
  margin: 10px auto;
  padding: 12px 10px;
  font-size: 1rem;
}
.room-create .details {
  position: relative;
}
.room-create .details h1 {
  color: #666;
}
.room-create .details button{
  width: 90%;
  position: absolute;
  bottom: 10px;
}
.room-create .details ul {
  list-style: none;
  margin-bottom: 80px;
}
.room-create .details li {
  font-size: 0.8rem;
  position: relative;
  padding-left: 25px;
  margin: 10px 0px;
}

.uppy-DragDrop-container {
  color: #666;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: none;
  height: 200px;
  outline: none;
}
.uppy-DragDrop-container:hover {
  background: none;
}
.uppy-DragDrop-input {
  display: none;
}
.uppy-DragDrop-label {
  background: url(../images/save_alt-blue-36dp.svg) no-repeat center top;
  background-size: 80px 80px;
  padding-top: 90px;
}
.uppy-DragDrop-overlay {
  width: 100%;
  background: #ccc;
  z-index: 999;
  position: absolute;
  top: 65px;
  bottom: 0px;
  opacity: 0.25;
}
.UppyIcon, .uppy-DragDrop-arrow {
  display: none !important;
}
.uppy-DragDrop-browse {
  display: block;
  cursor: pointer;
  width: 140px;
  border: 1px solid #2E9BD4;
  background: #2E9BD4;
  color: #fff !important;
  margin: 10px auto;
  padding: 12px 10px;
  font-size: 1rem;
}
.uppy-DragDrop--is-dragdrop-supported {
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}
.uppy-DragDrop-inner {
  padding: 30px 20px !important; 
}
.uppy-ProgressBar {
  width: 100%;
  height: 44px;
  border: 1px solid #1891D0;
  background: #CCC;
  color: #fff;
  font-size: 1rem;
  position: relative;
}
.uppy-ProgressBar-inner {
  height: 42px;
  background: #1891D0;
  border: none;
}
.uppy-ProgressBar-percentage {
  color: white;
  text-align: center;
  line-height: 42px;
  position: relative;
  top: -42px;
}
.uppy-ProgressBar-percentage::after {
  content: "%";
}

.rooms {
  font-size: 0.9rem;
}
.rooms .room-header {
  margin-bottom: 50px;
}
.rooms .room-header a {
  font-weight: bold;
}
.rooms .list-item-col-header {
  opacity: 0.75;
}
.rooms .empty {
  width: 100%;
  text-align: center;
}
.rooms .empty h2{
  color: #333;
}
.rooms .empty a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}
.room {
  width: 100%;
  max-width: 1000px;
  margin: 50px auto;
}
.room h1 {
  color: white;
  text-shadow: 1px 1px rgba(0,0,0,0.25);
}
.room-link {
  padding: 12px 40px;
}
.room-exp {
  font-size: 0.75rem !important;
  padding: 4px 0px;
  opacity: 0.75;
}
.player {
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}
.room-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.room-header h1 {
  flex: 3;
  margin: 15px 0px;
}
.room-header .actions {
  flex: 1;
  margin: 15px 0px;
}
.room-header .actions a{
  color: white;
  text-decoration: none;
  line-height: 46px;
}

.setup {
  margin-top: 0px;
}
.setup .download {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}
.setup .download label {
  height: 44px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  display: block;
  position: initial;
}
.setup .download .download-button {
  color: #666666;
  font-size: 0.8rem;
  text-align: left;
  padding-right: 20px;
  flex: 1;
  position: relative;
}
.setup .download .download-button:last-of-type {
  padding-right: 0px;
}
.setup .download .download-button svg {
  fill: #666666;
  height: 1.1rem;
  width: 1.1rem;
}
.setup .download .download-button .spacer {
  width: 2px;
  height: 44px;
  background-color: #707070;
  opacity: 0.25;
  position: absolute;
  top: 0px;
  right: 10px;
}

.login.box {
  padding: 0px;
  border: none;
}
.login .grid-col {
  padding: 30px;
}
.login .header {
  margin: 0px;
  padding: 0px;
}
.login .sub-header {
  color: #666;
  font-size: 0.9rem;
  margin: 10px 0px;
}
.login .sub-header a {
  color: #1891D0;
  text-decoration: none;
}
.login form {
  margin: 20px 0px;
}
.login input {
  margin: 10px 0px;
}
.login fieldset {
  padding: 5px 0px;
}
.login aside {
  color: white;
  text-align: center;
  background: url(../images/background_aside.png) no-repeat center center;
  background-size: cover;
  position: relative;
}
.login aside .message {
  width: 90%;
  height: 50%;
  overflow: visible;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}
.login aside .spacer {
  width: 40%;
  height: 1px;
  margin: 0 auto;
  background: white;
  display: block;
}
.login aside h2 {
  font-size: 2.2rem;
  padding: 15px;
}
.login aside h3 {
  font-size: 1rem;
  padding: 15px;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden {
  display: none !important;
}

/*
* Hide only visually, but have it available for screen readers:
* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*
* 1. For long content, line feeds are not interpreted as spaces and small width
*    causes content to wrap 1 word per line:
*    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
*/

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
* Extends the .sr-only class to allow the element
* to be focusable when navigated to via the keyboard:
* https://www.drupal.org/node/897638
*/

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
* Hide visually and from screen readers, but maintain layout
*/

.invisible {
  visibility: hidden;
}

/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    `contenteditable` attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that receive the `clearfix` class.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
*/

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
     * Printing Tables:
     * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

